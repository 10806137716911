<template>
  <div>
    <el-card class="box-card">
      <template #header>
        <div class="card-header">
          <el-form :inline="true" :model="formInline" class="demo-form-inline">
            <el-form-item label="设备IMEI">
              <el-input placeholder="设备IMEI" v-model="formInline.imei"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button @click="addSearch" type="primary">{{ $t('device.inquire') }}</el-button>
              <el-button @click="resetSearch" type="primary">{{ $t('device.reset') }}</el-button>
            </el-form-item>
          </el-form>
        </div>
      </template>
      <el-row :gutter="20">
        <el-col>
          <div class="grid-content bg-purple">
            <el-button @click="setAlarmInt" type="primary"
                       v-if="$store.state.pageAction.indexOf('group.alarm_interval')> -1">{{
                $t('group.alarm_interval')
              }}
            </el-button>
            <el-table
                :data="alarmData"
                :default-sort="{prop: 'date', order: 'descending'}"
                border
                stripe
                style="width: 100%"
            >
              <el-table-column :label="$t('group.alarm_object')" prop="type_title"></el-table-column>
              <el-table-column :label="$t('group.normal_range')" prop="tip"></el-table-column>
              <el-table-column :label="$t('group.operator')" prop="name"></el-table-column>
              <el-table-column :label="$t('group.issue_time')" prop="create_at"></el-table-column>
              <el-table-column :label="$t('device.operating')">
                <template #default="scope">
                  <el-button @click="handleList(scope.row)" type="text" size="small"
                             v-if="$store.state.pageAction.indexOf('device.devices')> -1">{{
                      $t('device.devices')
                    }}
                  </el-button>
                  <el-button @click="handleDelete(scope.row.id)" type="text" size="small"
                             v-if="$store.state.pageAction.indexOf('device.delete')> -1">{{
                      $t('device.delete')
                    }}
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
                :current-page="formInline.page"
                :page-size="formInline.size"
                :page-sizes="[10, 20, 50]"
                :total="pageTotal"
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                fixed="right"
                layout="total, sizes, prev, pager, next, jumper"
            ></el-pagination>
            <el-dialog
                @close="resetForm"
                center
                ref="setAlarmForm"
                :title="$t('group.alarm_setting')"
                top="8vh"
                v-model="setDialog"
            >
              <el-form ref="setAlarmForm" label-width="100px">
                <!--                <el-form-item :label="$t('index.device_type')" prop="device_type">-->
                <!--                  <el-select clearable :placeholder="$t('index.device_type')" v-model="infoForm.device_type">-->
                <!--                    <el-option-->
                <!--                        :key="item.value"-->
                <!--                        :label="item.text"-->
                <!--                        :value="item.value"-->
                <!--                        v-for="item in deviceType"-->
                <!--                    ></el-option>-->
                <!--                  </el-select>-->
                <!--                </el-form-item>-->
                <el-form-item :label="$t('group.alarm_object')" prop="type">
                  <el-select clearable :placeholder="$t('group.alarm_object')" v-model="infoForm.type">
                    <el-option
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                        v-for="item in typeOpts"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item :label="$t('group.normal_range')">
                  <div style="display:flex;">
                    <el-input :placeholder="$t('group.min')" style="width:120px;" v-model="infoForm.min"></el-input>
                    <span style="margin:0px 10px;">_</span>
                    <el-input :placeholder="$t('group.max')" style="width:120px;" v-model="infoForm.max"></el-input>
                  </div>
                </el-form-item>
                <el-form-item :label="$t('group.select_device')">
                  <el-button @click="addAlarm" type="primary">{{ $t('device.add') }}</el-button>
                  <el-table
                      :show-header="false"
                      max-height="200"
                      empty-text=" "
                      size="small"
                      class="customer-table"
                      :data="infoForm.imei_list"
                  >
                    <el-table-column>
                      <template #default="props">
                        <p>{{ props.row }}</p>
                      </template>
                    </el-table-column>
                    <el-table-column>
                      <template #default="scope">
                        <el-button type="text" @click="delChange(scope.row)">
                          <el-icon>
                            <close/>
                          </el-icon>
                        </el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                  <!-- <el-input
                    style="width:300px;margin-right:10px;"
                    readonly
                    v-model="infoForm.imei_list"
                  ></el-input>-->

                  <!-- <el-button @click="delAlarm" type="primary">删除</el-button> -->
                </el-form-item>
              </el-form>
              <template #footer>
                <span class="dialog-footer">
                  <el-button @click="setDialog = false">{{ $t('device.cancel') }}</el-button>
                  <el-button @click="alarmSet" type="primary">{{ $t('device.confirm') }}</el-button>
                </span>
                <p style="margin-top:20px;">{{ $t('group.alarm_note') }}</p>
              </template>
            </el-dialog>
            <el-dialog :title="dialogTit" center v-model="addDialog" width="70%">
              <div style="padding-bottom:60px;">
                <el-form :model="addform" :inline="true" ref="searchForm">
                  <el-form-item label="IMEI" prop="imei_keyword">
                    <el-input :placeholder="$t('notice.enter_imei')" v-model="addform.imei_keyword"></el-input>
                  </el-form-item>
                  <el-form-item :label="$t('index.device_type')" prop="type">
                    <el-select clearable :placeholder="$t('index.device_type')" v-model="addform.type">
                      <el-option
                          :key="item.value"
                          :label="item.text"
                          :value="item.value"
                          v-for="item in deviceType"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item :label="$t('device.device_status')" prop="online">
                    <el-select :placeholder="$t('device.device_status')" v-model="addform.online">
                      <el-option :label="$t('index.online')" value="1"></el-option>
                      <el-option :label="$t('index.offline')" value="0"></el-option>
                      <el-option :label="$t('index.stripping_outline')" value="2"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item :label="$t('device.vesting')" prop="client_id">
                    <el-cascader
                        :options="clientData"
                        :placeholder="$t('device.vesting')"
                        v-model="addform.client_id"
                    ></el-cascader>
                  </el-form-item>
                  <el-form-item>
                    <el-button @click="addDeviceSearch" type="primary">{{ $t('device.inquire') }}</el-button>
                    <el-button @click="resetaddSearch">{{ $t('device.reset') }}</el-button>
                    <el-button @click="handleAddDevice" type="primary">{{ $t('group.save') }}</el-button>
                  </el-form-item>
                </el-form>
                <!-- <el-button type="primary" @click="addList">添加</el-button> -->
                <el-table
                    :data="deviceData"
                    @select="handleSelect"
                    @select-all="handleSelectAll"
                    ref="deviceTable"
                >
                  <el-table-column type="selection" width="55"></el-table-column>
                  <el-table-column :label="$t('index.device')" property="imei" width="150"></el-table-column>
                  <el-table-column :label="$t('index.device_type')" property="type"></el-table-column>
                  <el-table-column :label="$t('device.device_status')" property="online"></el-table-column>
                  <el-table-column :label="$t('index.device_name')" property="name"></el-table-column>
                  <el-table-column :label="$t('device.creation_time')" property="create_at"></el-table-column>
                </el-table>
                <el-pagination
                    :current-page="addform.page"
                    :page-size="addform.size"
                    :total="addCount"
                    @current-change="handleCurrentChangeadd"
                    @size-change="handleSizeChangeadd"
                    layout="total, prev, pager, next, jumper"
                ></el-pagination>
              </div>
            </el-dialog>
            <el-dialog center :title="$t('device.devices')" v-model="listDialog" width="70%">
              <div style="padding-bottom:60px;">
                <el-form :model="addform" :inline="true" ref="searchForm">
                  <el-form-item label="IMEI" prop="imei_keyword">
                    <el-input :placeholder="$t('notice.enter_imei')" v-model="addform.imei_keyword"></el-input>
                  </el-form-item>
                  <el-form-item :label="$t('index.device_type')" prop="type">
                    <el-select clearable :placeholder="$t('index.device_type')" v-model="addform.type">
                      <el-option
                          :key="item.value"
                          :label="item.text"
                          :value="item.value"
                          v-for="item in deviceType"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item :label="$t('device.device_status')" prop="online">
                    <el-select :placeholder="$t('device.device_status')" v-model="addform.online">
                      <el-option :label="$t('index.online')" value="1"></el-option>
                      <el-option :label="$t('index.offline')" value="0"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item>
                    <el-button @click="addDeviceSearch" type="primary">{{ $t('device.inquire') }}</el-button>
                    <el-button @click="resetSearch">{{ $t('device.reset') }}</el-button>
                  </el-form-item>
                </el-form>
                <el-table :data="deviceDatas" ref="deviceTable">
                  <el-table-column :label="$t('index.device')" property="imei" width="150"></el-table-column>
                  <el-table-column :label="$t('index.device_type')" property="type"></el-table-column>
                  <el-table-column :label="$t('device.device_status')" property="online"></el-table-column>
                  <el-table-column :label="$t('index.device_name')" property="name"></el-table-column>
                  <el-table-column :label="$t('device.creation_time')" property="create_at"></el-table-column>
                </el-table>
                <el-pagination
                    :page-size="addform.size"
                    :total="listCount"
                    @current-change="handleCurrentChangeList"
                    @size-change="handleSizeChangeList"
                    layout="total, prev, pager, next, jumper"
                ></el-pagination>
              </div>
            </el-dialog>
          </div>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>
<script>
import client from "@/model/client";
import control from "@/model/control";
import device from "@/model/device";

export default {
  name: "groupAlarm",
  data() {
    return {
      pageTotal: 0,
      deviceType: [],
      formInline: {
        imei: "",
        page: 1,
        size: 10
      },
      clientData: [],
      alarmData: [],
      defaultProps: {
        children: "children",
        label: "name"
      },
      infoForm: {
        id: "",
        device_type: "",
        type: "",
        imei_list: [],
        max: "",
        min: ""
      },
      addform: {
        page: 1,
        size: 8,
        imei: "",
        imei_keyword: "",
        type: "",
        client_id: "",
        online: ""
      },
      deviceData: [],
      dialogTit: this.$t('group.add_device'),
      addDialog: false,
      setDialog: false,
      selectDevice: [],
      listDialog: false,
      deviceDatas: [],
      listCount: 0,
      addCount: 0,
      typeOpts: [
        {label: this.$t('detail.heart'), value: 1},
        {label: this.$t('detail.temp'), value: 2},
        {label: this.$t('detail.diastolic'), value: 3},
        {label: this.$t('detail.systolic'), value: 4}
      ]
    };
  },
  async mounted() {
    await this.clientList();
    await this.alarmList();
    await this.deviceTypeArr();
  },

  methods: {
    async deviceTypeArr() {
      const ret = await device.config();
      if (ret.code === 200) {
        this.deviceType = ret.data;
      }
    },
    async clientList() {
      const ret = await client.list();
      if (ret.code === 200) {
        this.clientData = this.handleClientTree(ret.data)
      }
    },
    handleClientTree(data) {
      if (data.length === 0) {
        return []
      }
      let options = []
      data.forEach(item => {
        options.push({
          value: item.id,
          label: item.name
        })
        if (item.children.length > 0) {
          options.push({
            value: item.id,
            label: item.name,
            children: this.handleClientTree(item.children)
          })
        }
      })
      return options
    },
    // 告警列表
    async alarmList() {
      const ret = await control.alarm(this.formInline);
      if (ret.code === 200) {
        this.alarmData = ret.data.list;
        this.pageTotal = ret.data.count;
      }
    },
    // 设置报警参数
    async alarmsetFun() {
      const params = JSON.parse(JSON.stringify(this.infoForm))
      params.imei_list = params.imei_list.toString()
      const ret = await control.alarmSet(params);
      if (ret.code === 200) {
        this.infoForm.imei_list = [];
        this.setDialog = false;
        this.$message.success(ret.message);
        this.alarmList();
      } else {
        this.$message.error(ret.message);
      }
    },
    // 添加设备 - 选择设备
    handleSelect(selection, row) {
      const index = this.selectDevice.indexOf(row.imei);
      if (index > -1) {
        this.selectDevice.splice(index, 1);
      } else {
        this.selectDevice.push(row.imei);
      }
    },
    // 添加设备 - 全选设备
    handleSelectAll(selection) {
      if (selection.length === 0) {
        this.deviceData.forEach(v => {
          let index = this.selectDevice.indexOf(v.imei);
          if (index > -1) {
            this.selectDevice.splice(index, 1);
          }
        });
      } else {
        this.deviceData.forEach(v => {
          let index = this.selectDevice.indexOf(v.imei);
          if (index < 0) {
            this.selectDevice.push(v.imei);
          }
        });
      }
    },
    // 设备列表
    async deviceList() {
      const ret = await device.group(this.addform);
      if (ret.code === 200) {
        this.deviceData = ret.data.list;
        this.deviceDatas = ret.data.list;
        this.listCount = ret.data.count;
        this.addCount = ret.data.count;
        setTimeout(() => {
          this.deviceData.forEach(v => {
            this.$refs.deviceTable.toggleRowSelection(
                v,
                this.selectDevice.indexOf(v.imei) > -1
            );
          });
        });
      }
    },
    addAlarm() {
      this.dialogTit = this.$t('group.add_device');
      this.addDialog = true;
      // this.infoForm.imei_list = [];
      this.addform.imei = [];
      this.addform.imei_keyword = "";
      this.addform.type = "";
      this.addform.min = "";
      this.addform.max = "";
      this.selectDevice = JSON.parse(JSON.stringify(this.infoForm.imei_list));
      this.deviceList();
    },
    handleAddDevice() {
      this.infoForm.imei_list = JSON.parse(JSON.stringify(this.selectDevice));
      this.addDialog = false;
    },
    delAlarm() {
      this.addDialog = true;
      this.dialogTit = this.$t('group.delete_device');
      this.selectDevice = JSON.parse(JSON.stringify(this.infoForm.imei_list));
      this.addform.imei_keyword = "";
      this.addform.imei =
          this.selectDevice.length == 0 ? [""] : this.selectDevice.join(",");
      this.deviceList();
    },
    commandOff() {
      this.directDialog = true;
    },
    alarmSet() {
      this.alarmsetFun();
    },
    handleList(row) {
      this.setDialog = true;
      this.infoForm.imei_list = row.imei_list.split(',');
      this.infoForm.id = row.id;
      this.infoForm.type = row.type;
      this.infoForm.min = row.min
      this.infoForm.max = row.max
    },
    async handleDelete(id) {
      this.$confirm(this.$t('device.delete_note'), this.$t('device.tip'), {
        confirmButtonText: this.$t('device.confirm'),
        cancelButtonText: this.$t('device.cancel'),
        type: "warning"
      })
          .then(async () => {
            const ret = await control.delete({
              id: id
            });
            if (ret.code === 200) {
              this.$message.success(ret.message);
              this.alarmList();
            } else {
              this.$message.error(ret.message);
            }
          })
          .catch(() => {
          });
    },
    delChange(imei) {
      this.$confirm(this.$t('device.delete_note'), this.$t('device.tip'), {
        confirmButtonText: this.$t('device.confirm'),
        cancelButtonText: this.$t('device.cancel'),
        type: "warning"
      })
          .then(async () => {
            this.infoForm.imei_list.splice(
                this.infoForm.imei_list.findIndex(item => item.id === imei),
                1
            );
          })
          .catch(() => {
          });
    },
    setAlarmInt() {
      this.setDialog = true;
      this.infoForm.imei_list = [];
      this.infoForm.id = "";
      this.infoForm.type = "";
      this.infoForm.min = "";
      this.infoForm.max = "";
    },
    addSearch() {
      this.alarmList();
    },
    resetSearch() {
      this.formInline.imei_keyword = "";
      this.addform.online = "";
      this.addform.type = "";
      this.alarmList();
    },
    addDeviceSearch() {
      this.deviceList();
    },
    resetaddSearch() {
      this.addform.imei_keyword = "";
      this.addform.online = "";
      this.addform.type = "";
      this.deviceList();
    },
    handleSizeChange(val) {
      this.formInline.size = val;
      this.alarmList();
    },
    handleCurrentChange(val) {
      this.formInline.page = val;
      this.alarmList();
    },
    handleSizeChangeadd(val) {
      this.addform.size = val;
      this.deviceList();
    },
    handleCurrentChangeadd(val) {
      this.addform.page = val;
      this.deviceList();
    },
    handleSizeChangeList(val) {
      this.addform.size = val;
      this.deviceList();
    },
    handleCurrentChangeList(val) {
      this.addform.size = val;
      this.deviceList();
    },
    resetForm() {
      this.infoForm.device_type = "";
      this.infoForm.type = "";
      this.infoForm.imei_list = [];
      this.infoForm.max = "";
      this.infoForm.min = "";
    }
  }
};
</script>
<style scoped>
.demo-form-inline {
  margin-top: 20px;
}

.card-header {
  display: flex;
  align-items: center;
}

.el-table {
  margin-top: 20px;
}

.el-pagination {
  margin: 30px 0px;
  float: right;
}

.customer-table {
  margin-top: 0px;
}

.customer-table /deep/ .has-gutter {
  display: none !important;
}

.el-table::before {
  height: 0px !important;
}

.customer-table /deep/ .el-table__cell {
  padding: 0px !important;
}

.customer-table th {
  border: none !important;
  border-bottom: none !important;
}

.customer-table thead {
  display: none;
  border: none !important;
}

.customer-table td,
.customer-table th.is-leaf {
  border: none;
}

.customer-table .el-table--border,
.el-table--group {
  border: none;
}

.customer-table .el-table--border::after,
.el-table--group::after {
  width: 0;
}

.customer-table .customer-table::before {
  width: 0;
}

.customer-table .customer-table .el-table__fixed-right::before,
.el-table__fixed::before {
  width: 0;
}
</style>
